
















import Vue from 'vue';

export default Vue.extend({
	name: 'Jury',
	data: function(){
		return {
			jury: [
				{ name: "Виктор Наумов", company: "Dentons (офис Санкт-Петербург)", post: "управляющий партнер", img: "naumov.jpg" },
				{ name: "Евгений Акимов", company: "Сбербанк", post: "управляющий директор-начальник Управления принудительного взыскания и банкротства Департамента по работе с проблемными активами", img: "akimov.jpg" },
				{ name: "Максим Арефьев", company: "X5 Retail Group", post: "директор по правовой поддержке", img: "arefev.jpg" },
				{ name: "Екатерина Дедова", company: "Bryan Cave Leighton Paisner Russia", post: "партнер Корпоративной практики / слияния и поглощения", img: "dedova.png" },
				{ name: "Борис Болтянский", company: "Право.ru", post: "главный редактор", img: "boltyanskiy.jpg" },
			]
		}
	}
});
