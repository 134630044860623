export const getAssetURL = {
    methods: {
        getAssetUrl(fileName){
            try {
                return require(`@/assets/${fileName}`)
            } catch(e){
                console.groupCollapsed('getAssetUrl():')
                console.warn(e)
                console.groupEnd()
                return '';
            }
        }
    },
}