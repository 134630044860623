
















import Vue from 'vue';

export default Vue.extend({
	name: 'Speakers',
	data: function(){
		return {
			speakers: [
				{ name: "Дмитрий Макаренко", company: "Национальная Юридическая Служба «Амулекс»", post: "основатель", img: "makarenko.jpg" },
				{ name: "Виктор Наумов", company: "Dentons (офис Санкт-Петербург)", post: "управляющий партнер", img: "naumov.jpg" },
				{ name: "Анна Костыра", company: "Deloitte Legal СНГ", post: "управляющий партнер", img: "kostira.jpeg" },
				{ name: "Олеся Жучкова", company: "Рокетбанк", post: "руководитель юридического департамента", img: "zhuchkova.jpg" },
				{ name: "Евгений Глазов", company: "AB INBEV EFES", post: "директор по этике и комплаенсу", img: "glazov.jpg" },
				{ name: "Андрей Дуюнов", company: "ГАЗПРОМ НЕФТЬ", post: "руководитель программы трансформации и внедрения изменений правовой функции", img: "d.jpeg" },
				{ name: "Арина Сергиевская", company: "ЮНИЛЕВЕР", post: "руководитель направления по соблюдению деловой этики", img: "sergievskaya.jpg" },
				{ name: "Владимир Зайцев", company: "SIEMENS", post: "руководитель налоговой службы", img: "zaytsev.jpg" },
				{ name: "Маргарита Хоменко", company: "X5 RETAIL GROUP", post: "начальник управления по комплаенсу", img: "khomenko.jpg" },
				{ name: "Нурида Ибрагимова", company: "Амулекс", post: "глава департамента контроля качества", img: "ibragimova.jpg" },
				{ name: "Владимир Юрасов", company: "Коллегия адвокатов «Юрасов, Ларин и партнеры», Компания «Электронное Облако»", post: "адвокат", img: "jurasov.jpg" },
				{ name: "Алексей Поповцев", company: "СИБУР", post: "старший юрист", img: "popovtsev.jpg" },
				{ name: "Татьяна Ветошкина", company: "Центр Правового Аутсорсинга «ВАШЕ ПРАВО»", post: "президент", img: "vetoshkina.jpg" },
			]
		}
	}
});
