











































import Vue from 'vue';
import NavBar from './components/NavBar.vue';
import About from './components/About.vue';
import Programme from './components/Programme.vue';
import Speakers from './components/Speakers.vue';
import Partners from './components/Partners.vue';
import LTA from './components/LTA.vue';
import Place from './components/Place.vue';
import PhotoSlider from './components/PhotoSlider.vue';
import Contacts from './components/Contacts.vue';

export default Vue.extend({
	name: 'app',
	components: { NavBar, About, Programme, Speakers, Partners, LTA, Place, PhotoSlider, Contacts },
	mounted(){
		let currentX = 0, currentY = 0, movementConstant = .025,
			spheres = Array.from(document.querySelectorAll('.sphere')), self = this

		document.addEventListener('mousemove', function(e){
			if(currentX == 0) currentX = e.pageX;
			let xdiff = e.pageX - currentX;
			currentX = e.pageX;
			if(currentY == 0) currentY = e.pageY;
			let ydiff = e.pageY - currentY;
			currentY = e.pageY;


			spheres.forEach((sphere, idx) => {
				let movement = (0 + 1) * (xdiff * movementConstant);
				let movementy = (0 + 1) * (ydiff * movementConstant);
				let rect = sphere.getBoundingClientRect();
				let newX = rect.left + movement;
				let newY = rect.top + movementy;
				sphere.style.left = newX + 'px';
				sphere.style.top = newY + 'px';
			})
		})
	}
});
