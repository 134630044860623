

























import Vue from 'vue';
//@ts-ignore
import { Carousel, Slide } from 'vue-carousel';
//@ts-ignore
import GLightbox from 'glightbox'

export default Vue.extend({
	name: 'PhotoSlider',
	components: { Carousel, Slide, GLightbox },
	data(){
		return { lbInstance: null, instanceId: '' }
	},
    created() {
        let self = this
        self.instanceId = self.randomString()
        self.lbInstance = GLightbox({
            selector: self.instanceId
        })
    },
    methods: {
        open(e:Event, slideIdx:number){
            let slide = this.$refs[this.instanceId + '-' + slideIdx]
			e.preventDefault()
			console.log(this.lbInstance)
            this.lbInstance.open(slide[0])
        },
        randomString: () => 'LB_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    }
});
