
























import Vue from 'vue';
//@ts-ignore
import { Carousel, Slide } from 'vue-carousel';

export default Vue.extend({
	name: 'Partners',
	components: { Carousel, Slide },
	data(){
		return {
			partners: [
				{ title:"Амулекс", logo: "amulex.png", href: "https://amulex.ru/" },
				{ title:"Ваше право", logo: "vashe_pravo.jpg", href: "http://washeprawo.ru/" },
				{ title:"Труд Легал", logo: "trud_legal.jpg", href: "https://trood.legal/" },
				{ title:"Электронное облако", logo: "oblako.png", href: "https://cloudserver.ru/?lang=ru" },
				{ title:"Soft skills law academy", logo: "ssla.png", href: "http://softskillslawacademy.com/" },
			]
		}
	}
});
