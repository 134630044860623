



































































































import Vue from 'vue';
import Jury from './Jury'

export default Vue.extend({
	name: 'LTA',
	components: { Jury },
	data(){
		return {
			stages: [
				{ date: "с 20 августа до 31 октября<br>2019 года", desc: "Заполнение и сбор анкет" },
				{ date: "с 1 до 15 ноября<br>2019 года", desc: "Обработка анкет" },
				{ date: "с 18 до 29 ноября<br>2019 года", desc: "Оценивание проектов, заседание жюри" },
				{ date: "5 декабря<br>2019 года", desc: "Объявление победителей – на церемонии вручения лидеров рейтинга \"Право.ru-300\"" }
			]
		}
	}
});
