import Vue from 'vue'
import App from './App.vue'

import { LayoutPlugin, NavbarPlugin, ButtonPlugin, VBScrollspyPlugin  } from 'bootstrap-vue'
import '../node_modules/bootstrap/scss/bootstrap.scss'
import '../node_modules/glightbox/dist/css/glightbox.min.css'

//@ts-ignore
import { getAssetURL } from './mixins/getAssetURL'

Vue.config.productionTip = false

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(VBScrollspyPlugin)
Vue.mixin(getAssetURL)

new Vue({
  render: h => h(App),
}).$mount('#app')
